import React, { useState, useEffect } from 'react'
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, ScrollView, Text, View, Linking, Pressable, Platform } from 'react-native';
import { AntDesign, EvilIcons, Ionicons, MaterialIcons, MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';
import { db, auth } from '../../db/firestore';
import { doc, getDoc, getDocs, collection, query, where } from "firebase/firestore";


const weekdayArr = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const monthNameArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];

const chamberType = {
    H: 1,
    S: 2,
}
const documentType = {
    B: 1,
    R: 2,
}

const Meeting = ({ route, navigation, userInfo }) => {
    const { id, page } = route.params;
    const email = auth.currentUser.email;
    const [eventData, setEventData] = useState();
    const [committees, setCommittees] = useState();
    const [summary, setSummary] = useState();
    const [noStart, setNoStart] = useState();
    const [month, setMonth] = useState();
    const [weekday, setWeekday] = useState();
    const [dateNum, setDateNum] = useState();
    const [time, setTime] = useState();
    const [location, setLocation] = useState();
    const [locationLink, setLocationLink] = useState(null);
    const [agenda, setAgenda] = useState(null);
    const [extraDocs, setExtraDocs] = useState(null);
    const [bills, setBills] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const headerHeight = useHeaderHeight();

    const getData = async () => {
        setPageLoading(true)
        const docRef = doc(db, page, id);
        await getDoc(docRef)
            .then(doc => doc.data())
            .then(async (event) => {
                const committeeArr = [];
                const isNoDate = event.noDate ? event.noDate : false;
                const isUponAdjourn = event.uponAdjourn ? event.uponAdjourn : false;
                if (event.noDate && event.start == null) {
                    setNoStart(true)
                }
                
                // Convert UTC to local time
                const startTimeUTC = event.start ? new Date(event.start) : null;
                const localTime = startTimeUTC ? startTimeUTC.toLocaleString([], { hour: '2-digit', minute: '2-digit', hour12: true }) : "(TBD)";
                
                let formattedTime = localTime;
                if (isNoDate) {
                    formattedTime = "(TBD)";
                }
                if (isUponAdjourn) {
                    formattedTime = event.adjournText ? `${event.adjournText}` : "(TBD)";
                }

                if (event.committees) {
                    for (let i = 0; i < event.committees.length; i += 1) {
                        committeeArr.push(<Text key={i} style={styles.textStyle}>{event.committees[i]}</Text>)
                    }
                }

                if ((event.agenda  && event.useAgenda) || (event.agendaUri && !event.useAgenda)) {
                    if (event.useAgenda) {
                        setAgenda(<Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => navigation.navigate('ExternalView', { title: "Meeting Agenda", html: event.agenda, options: { headerBackTitleVisible: false } })}
                        >
                            <View style={styles.rowContainer}>
                                <MaterialCommunityIcons name="view-agenda-outline" size={24} color={constants.ICON_COLOR} />
                                <Text style={[styles.textStyle, { marginTop: 2, marginLeft: 10 }]}>Agenda</Text>
                            </View>
                        </Pressable>)
                    } else {
                        setAgenda(<Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => navigation.navigate('ExternalView', { title: "Meeting Agenda", link: event.agendaUri, options: { headerBackTitleVisible: false } })}
                        >
                            <View style={[styles.rowContainer]}>
                                <MaterialCommunityIcons name="view-agenda-outline" size={24} color={constants.ICON_COLOR} />
                                <Text style={[styles.textStyle, { marginTop: 2, marginLeft: 10 }]}>Agenda</Text>
                            </View>
                        </Pressable>)
                    }
                }

                if (event.extraDocuments?.length > 0) {
                    const docButtons = []
                    for (let i = 0; i < event.extraDocuments.length; i += 1) {
                        const item = event.extraDocuments[i];
                        docButtons.push(
                            <Pressable
                                key={item.name}
                                style={[styles.button, styles.buttonClose]}
                                onPress={() => navigation.navigate('ExternalView', { title: "Extra Documents", link: item.url, options: { headerBackTitleVisible: false } })}
                            >
                                <Text style={styles.textStyle}>{item.name}:</Text>
                            </Pressable>
                        )
                    }
                    setExtraDocs(docButtons)
                }

                if (event.billsResolutions?.length > 0 && event.billsResolutions[0] !== "") {
                    const tempBills = []; // Temporary array to store all bill pressables

                    for (let i = 0; i < event.billsResolutions.length; i += 1) {
                        const ch = chamberType[event.billsResolutions[i][0]];
                        const doc = documentType[event.billsResolutions[i][1]];
                        const num = event.billsResolutions[i].substring(2);
                        const docRef = collection(db, 'legislation');
                        const q = query(
                            docRef,
                            where('chamber', '==', ch),
                            where('documentType', '==', doc),
                            where('number', '==', num),
                        );

                        await getDocs(q)
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    const data = doc.data();
                                    const bill = event.billsResolutions[i];

                                    // Check if the bill is in trackedLegislationTitles
                                    const isTracked = userInfo.trackedLegislationTitles.includes(bill);

                                    // Check if the bill is in trackedLanguageBills and get the keyword if it exists
                                    const trackedLanguage = userInfo.trackedLanguageBills.find(item => item.bill === bill);
                                    const keyword = trackedLanguage?.keyword;

                                    // Build the display content
                                    const firstLine = isTracked ? `${bill} (Tracked):` : `${bill}:`;

                                    // Add the Pressable to the temporary array
                                    tempBills.push(
                                        <Pressable
                                            key={data.legislationId}
                                            style={[styles.button, styles.buttonClose]}
                                            onPress={() => navigation.navigate('Bill', {
                                                title: bill,
                                                billId: data.legislationId,
                                                email: email,
                                                options: { headerBackTitleVisible: false }
                                            })}
                                        >
                                            <View style={styles.rowContainer}>
                                                <AntDesign name="filetext1" size={22} color={constants.ICON_COLOR} style={{ marginRight: 4, marginBottom: 6 }} />
                                                <Text style={styles.textStyle}>{firstLine}</Text>
                                            </View>

                                            {keyword && (
                                                <View style={styles.rowContainer}>
                                                    <FontAwesome name="key" size={22} color={constants.ICON_COLOR} style={{ marginRight: 4, marginBottom: 6 }} />
                                                    <Text style={styles.textStyle}> {`"${keyword.toUpperCase()}"`}</Text>
                                                </View>
                                            )}

                                            {/* Last Line: Bill Title */}
                                            <Text style={styles.subTextStyle}>{data.title}</Text>
                                        </Pressable>
                                    );
                                });
                            })
                            .catch((error) => {
                                console.log("Error getting documents: ", error);
                            });
                    }

                    // Update state after processing all bills
                    setBills(tempBills);
                }
                setEventData(event)
                if (startTimeUTC) {
                    setWeekday(weekdayArr[startTimeUTC.getDay()]);
                    setMonth(monthNameArr[startTimeUTC.getMonth()]);
                    setDateNum(startTimeUTC.getDate());
                }
                setSummary(event.subject);
                setTime(formattedTime);
                setLocation(event.location);
                setLocationLink(event.livestreamUrl);
                setCommittees(committeeArr);
            })
        setPageLoading(false)
    }

    useEffect(() => {
        if (!eventData) {
            getData();
        }
    }, []);

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                {pageLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                    :
                    <View>
                        <View style={[styles.rowContainer, {
                            alignSelf: 'center',
                            display: 'flex',
                            width: '96%',
                            marginBottom: 2,
                        }]}
                        >
                            <View style={[styles.calendarContainer]}>
                                <View style={styles.calendarMonth}>
                                    <Text style={styles.monthText}>{noStart ? "No" : month}</Text>
                                </View>
                                <View style={[styles.calendarDate]}>
                                    <Text style={styles.weekdayText}>{noStart ? null : weekday}</Text>
                                    <Text style={[styles.dateText, { fontSize: noStart ? 11.5 : 16, marginTop: noStart ? 10 : 0 }]}>{noStart ? 'Date' : dateNum}</Text>
                                </View>
                            </View>
                            <Text style={[styles.textStyle, , { flex: 2, fontSize: 16.7 }]}>{summary}</Text>
                        </View>
                        <View style={[styles.button, styles.buttonClose, { paddingLeft: 0, paddingRight: 0, opacity: .7 }]}>
                            <View style={{ alignSelf: 'center', width: '98%' }}>
                                <View style={[styles.rowContainer]}>
                                    <AntDesign name="team" size={17} color="white" style={{ marginTop: 3, marginBottom: 4, marginLeft: 2, marginRight: 5 }} />
                                    <View>
                                        {committees}
                                    </View>
                                </View>
                                <View style={styles.rowContainer}>
                                    <EvilIcons name="location" size={24} color="white" style={{ marginTop: 5, marginBottom: 8, marginRight: 5 }} />
                                    <Text style={{ ...styles.locationText }}>{location}</Text>
                                </View>
                                <View style={styles.rowContainer}>
                                    <Ionicons name="time-outline" size={19} color="white" style={{ marginTop: 0, marginLeft: 4, marginRight: 5 }} />
                                    <Text style={{ ...styles.subTextStyle, marginTop: 0 }}>{time}</Text>
                                </View>
                            </View>
                        </View>

                        <View>
                            {locationLink
                                ? <Pressable
                                    style={[styles.button, styles.buttonClose]}
                                    onPress={() => Linking.openURL(locationLink)}
                                >
                                    <View style={[styles.rowContainer]}>
                                        <MaterialIcons name="wifi" size={24} color={constants.ICON_COLOR} />
                                        <Text style={[styles.textStyle, { marginTop: 2, marginLeft: 10 }]}>Livestream Link</Text>
                                    </View>
                                </Pressable>
                                : null
                            }
                            {agenda ? agenda : null}
                        </View>
                        <ScrollView style={styles.scrollView}>
                            <View >
                                {bills}
                            </View>
                            <View style={{ marginTop: 10 }}>
                                {extraDocs ? <Text style={[styles.textStyle, { marginLeft: 10 }]}>Extra Documents:</Text> : null}
                                {extraDocs ? extraDocs : null}

                            </View>
                        </ScrollView>
                    </View>
                }
            </View>
        </View >
    )
}

export default Meeting

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
    },
    rowContainer: {
        flexDirection: 'row',
        flexGrow: 1,
        width: "95%",
    },
    scrollView: {
        marginTop: 10,
        height: '55%',
    },
    button: {
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    calendarContainer: {
        backgroundColor: 'white',
        height: 53.5,
        width: 48.5,
        borderColor: 'black',
        borderWidth: 2.65,
        borderRadius: 5,
        marginBottom: 10,
        marginRight: 7,
        marginTop: 4,
    },
    calendarMonth: {
        backgroundColor: '#D21F3C',
        height: 18,
        borderColor: 'gray',
        borderWidth: .09,
        borderBottomColor: 'black',
        borderBottomWidth: 1.6,
        borderTopRightRadius: 2.5,
        borderTopLeftRadius: 2.5,
        alignItems: 'center',
    },
    calendarDate: {
        alignItems: 'center',
    },
    monthText: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 12
    },
    weekdayText: {
        color: "#373737",
        marginBottom: -4,
        fontSize: 11,
        fontWeight: "bold",
    },
    dateText: {
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 16,
    },
    locationText: {
        color: "#e5e5e5",
        marginTop: 3,
        marginBottom: 6,
        fontSize: 14,
        fontWeight: "bold",
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginBottom: 2,
        marginLeft: 2,
        fontSize: 15,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        marginBottom: 6,
        fontSize: 14,
        fontWeight: "bold",
    },
    noEntriesText: {
        color: 'white',
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        textAlign: 'center',
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    icon: {
        color: 'white',
        marginRight: 5,
    },
    label: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
})