import React from "react";
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, Text, View, Pressable, Image, Linking, Platform, ScrollView } from 'react-native'
import { AntDesign, Fontisto, MaterialCommunityIcons, MaterialIcons, Feather } from '@expo/vector-icons';
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";


const AdditionalInfo = ({ route, navigation }) => {
    const { title, tier } = route.params;
    const headerHeight = useHeaderHeight();

    return (
        <View style={[styles.container, { marginTop: headerHeight }]}>
            <ScrollView >
                <View style={styles.centeredView}>
                    <Pressable
                        disabled={tier === '3' ? false : true}
                        style={[styles.button, styles.buttonClose, { opacity: tier === '3' ? 1 : .5}]}
                        onPress={() => navigation.navigate('OOGContainer', { title: "Office of the Governor" })}
                    >
                        <View style={{ flexDirection: 'row', width: 250 }}>
                            {tier === '3' ?
                                <MaterialCommunityIcons name="office-building-outline" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                :
                                <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                            }
                            <Text style={styles.textStyle}>Office of the Governor</Text>
                        </View>
                    </Pressable>
                    <Pressable
                        disabled={tier === '1' ? true : false}
                        style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1}]}
                        onPress={() => {
                            navigation.navigate('PrefilesTable', { title: "Prefiles" })
                        }}
                    >
                        <View style={{ flexDirection: 'row', width: 250 }}>
                            {tier === '1' ?
                                <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                :
                                <MaterialIcons name="history" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                            }
                            <Text style={styles.textStyle}>Prefiles</Text>
                        </View>
                    </Pressable>
                    <Pressable
                        disabled={tier === '1' ? true : false}
                        style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1}]}
                        onPress={() => navigation.navigate('SignedLegislationTable', { title: "Signed Legislation" })}
                    >
                        <View style={{ flexDirection: 'row', width: 250 }}>
                            {tier === '1' ?
                                <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                :
                                <MaterialCommunityIcons name="file-document-edit-outline" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                            }
                            <Text style={styles.textStyle}>Signed Legislation</Text>
                        </View>
                    </Pressable>
                    <Pressable
                        disabled={tier === '1' ? true : false}
                        style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1}]}
                        onPress={() => navigation.navigate('ContactInfo', { title: "Legislative Contact Info" })}
                    >
                        <View style={{ flexDirection: 'row', width: 250 }}>
                            {tier === '1' ?
                                <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                :
                                <AntDesign name="contacts" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                            }
                            <Text style={styles.textStyle}>Legislative Contacts</Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => Linking.openURL("https://twitter.com/GaHouseHub")}
                    >
                        <View style={{ flexDirection: 'row', width: 250 }}>
                            <FontAwesomeIcon icon={faXTwitter} size={24} color={constants.ICON_COLOR} style={[styles.buttonIcon, { marginTop: 27 }]} />
                            <Text style={styles.textStyle}>House News Feed</Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => Linking.openURL("https://twitter.com/GASenatePress")}
                    >
                        <View style={{ flexDirection: 'row', width: 250 }}>
                            <FontAwesomeIcon icon={faXTwitter} size={24} color={constants.ICON_COLOR} style={[styles.buttonIcon, { marginTop: 27 }]} />
                            <Text style={styles.textStyle}>Senate News Feed</Text>
                        </View>
                    </Pressable>
                </View>
            </ScrollView>
        </View>
    )
}

export default AdditionalInfo

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: constants.PRIMARY_DARK,
    },
    centeredView: {
        backgroundColor: constants.PRIMARY_DARK,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10
    },
    button: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: "center",
        borderRadius: 9,
        borderWidth: 2,
        borderColor: '#131313',
        elevation: 2,
        width: "95%",
        marginBottom: 10,
        alignItems: "center",
        ...Platform.select({
            ios: {
                height: 90,
                padding: 7,
            },
            android: {
                height: 78,
            },
            web: {
                height: 100,
                padding: 10,
            }
        })
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    buttonIcon: {
        marginLeft: '-7%',
        marginTop: 25,
    },
    viewWithShadow: {
        backgroundColor: 'white',
        borderRadius: 20,
        height: 26,
        width: 16,
        shadowColor: '#4B9CD3',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0,
        // !!!!!!!!--------change this to 7 and 5 to demo the shadow---------!!!!
        shadowRadius: 0,
        elevation: 0,
    },
    imageShadow: {
        marginTop: 20,
        marginRight: 15,
        marginLeft: '8%',
        height: 32,
        width: 20,
        borderRadius: 16,
        backgroundColor: 'white',
        shadowColor: '#4B9CD3',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        // change to .5
        shadowOpacity: 0,
        // !!!!!!!!--------change this to 7 and 5 to demo the shadow---------!!!!
        shadowRadius: 0,
        elevation: 0,
    },
    textStyle: {
        marginTop: 15,
        marginLeft: 10,
        fontSize: 17,
        fontWeight: "bold",
        color: constants.TITLE_COLOR,
        fontFamily: 'Verdana',
        lineHeight: 45,
    },
})
