import React, { useState, useEffect } from "react";
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../utils/constants";
import { StyleSheet, Text, View, Pressable, Image, Platform, ScrollView } from 'react-native'
import Constants from 'expo-constants'
import { AntDesign, Entypo, Fontisto, MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons';
import { db, auth } from '../db/firestore';
import { doc, updateDoc, getDoc } from "firebase/firestore";

const Home = ({ navigation, userInfo }) => {
    const [tier, setTier] = useState('1')
    const headerHeight = useHeaderHeight();

    useEffect(() => {
        if (userInfo) {
            setTier(userInfo.tier)
        }
    }, [userInfo])

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK, flex: 1 }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <ScrollView >
                    <View style={styles.centeredView}>
                        <Pressable
                            disabled={tier === '2' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '2' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('StateAgenciesTable', { title: "State Departments" })}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                {tier === '2' ?
                                    <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                    :
                                    <MaterialCommunityIcons name="clipboard-text-outline" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                                }
                                <Text style={styles.textStyle}>State Agency Events</Text>
                            </View>
                        </Pressable>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('MeetingsTable', { title: "Legislative Meetings", options: { headerBackTitleVisible: false } })}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                {tier === '1' ?
                                    <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                    :
                                    <AntDesign name="team" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                                }
                                <Text style={styles.textStyle}>Legislative Meetings</Text>
                            </View>
                        </Pressable>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('CalendarsTable', { title: "Calendar" })}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                {tier === '1' ?
                                    <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                    :
                                    <AntDesign name="calendar" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                                }
                                <Text style={styles.textStyle}>Legislative Calendars</Text>
                            </View>
                        </Pressable>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('BillTable', { title: "My Legislation" })}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                {tier === '1' ?
                                    <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                    :
                                    <AntDesign name="filetext1" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />

                                }
                                <Text style={styles.textStyle}>Lobby Report Page</Text>
                            </View>
                        </Pressable>
                        <Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => {
                                navigation.navigate('AdditionalInfo', { title: "Additional Info", tier: tier })
                            }}
                        >
                            <View style={{ flexDirection: 'row', width: 250 }}>
                                <Entypo name="dots-three-horizontal" size={26} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                <Text style={styles.textStyle}>Additional Information</Text>
                            </View>
                        </Pressable>
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

export default Home

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: constants.PRIMARY_DARK,
    },
    centeredView: {
        backgroundColor: constants.PRIMARY_DARK,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 40
    },
    button: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: "center",
        borderRadius: 9,
        borderWidth: 2,
        borderColor: '#131313',
        elevation: 2,
        width: "95%",
        marginBottom: 10,
        ...Platform.select({
            ios: {
                height: 100,
                padding: 10,
            },
            android: {
                height: 88,
                padding: 3,
            },
            web: {
                height: 100,
                padding: 10,
            }
        })
    },
    buttonOpen: {
        backgroundColor: "black",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    buttonIcon: {
        marginLeft: '-5%',
        marginTop: 25,
    },
    viewWithShadow: {

        backgroundColor: 'black',
        borderRadius: 20,
        height: 32,
        width: 20,
        shadowColor: '#4B9CD3',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 1,
        shadowRadius: 0,
        elevation: 5,
    },
    imageShadow: {
        marginTop: 20,
        marginRight: 15,
        height: 32,
        width: 20,
        borderRadius: 16,
        backgroundColor: 'black',
        shadowColor: '#4B9CD3',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: .5,
        shadowRadius: 0,
        elevation: 5,
    },
    textStyle: {
        marginTop: 15,
        marginLeft: 10,
        fontSize: 17,
        fontWeight: "bold",
        color: constants.TITLE_COLOR,
        lineHeight: 45,
        fontFamily: 'Verdana',
    },
})
