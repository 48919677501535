import algoliasearch from 'algoliasearch'

export const searchAlgolia = async (query, pageNum, filter) => {
    const client = algoliasearch('TEPCZDOS97', '8feb12c6f05c840e06ef46196a534be8');
    // If there are indexes other than legislation I want to search,
    // make the initIndex argument a variable and pass which index
    // as a parameter to searchAlgolia from the component
    const index = client.initIndex('legislation');
    if (pageNum !== undefined && filter) {
        return (await index.search(query, {
            facetFilters: filter,
            page: pageNum,
            hitsPerPage: 20,
        }));
    } else if (pageNum !== undefined && !filter) {
        return (await index.search(query, {
            page: pageNum,
            hitsPerPage: 20,
        }));
    } else if (pageNum === undefined && filter) {
        return (await index.search(query, {
            facetFilters: filter,
        }));
    } else {
        return (await index.search(query, {
            typoTolerance: false,
            restrictSearchableAttributes: ['firstReader', 'title'],
        }));
    }
}
