import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, FlatList, TextInput, Pressable, Platform } from 'react-native'
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { db } from '../../db/firestore';
import { doc, getDoc, getDocs, collection, updateDoc, query, where } from "firebase/firestore";
import { searchAlgolia } from '../../db/algolia.js';

const KeywordTracking = ({ route, userInfo }) => {
    const [currentTrackedWords, setCurrentTrackedWords] = useState([]);
    const [trackedData, setTrackedData] = useState();
    const [trackedCommitteeNames, setTrackedCommitteeNames] = useState([]);
    const [trackedLegislationTitles, setTrackedLegislationTitles] = useState([]);
    const [trackedEvents, setTrackedEvents] = useState([]);
    const [trackedLanguageBills, setTrackedLanguageBills] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [duplicateWarning, setDuplicateWarning] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const headerHeight = useHeaderHeight();

    const chamberType = {
        1: "H",
        2: "S",
    };
    const documentType = {
        1: "B",
        2: "R",
    };

    const buildLegisTitle = (item) => {
        return `${chamberType[item.chamber]}${documentType[item.documentType]}${item.number}`;
    }

    useEffect(() => {
        if (userInfo.trackedWords.length !== currentTrackedWords.length) {
            setTrackedData(userInfo.trackedWords ? userInfo.trackedWords : []);
            setTrackedEvents(userInfo.allTrackedEvents ? userInfo.allTrackedEvents : []);
            setTrackedCommitteeNames(userInfo.trackedCommitteeNames ? userInfo.trackedCommitteeNames : []);
            setTrackedLegislationTitles(userInfo.trackedLegislationTitles ? userInfo.trackedLegislationTitles : []);
            setTrackedLanguageBills(userInfo.trackedLanguageBills ? userInfo.trackedLanguageBills : []);
            setCurrentTrackedWords(userInfo.trackedWords)
            setPageLoading(false);
        }
    }, [userInfo]);

    // Function to split the billList into chunks of size 10
    const chunkArray = (array, size) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }
        return chunks;
    }

    const handleSaveKeyword = async () => {
        if (keyword && keyword.trim() !== '') {
            const newTracked = trackedData ? [...trackedData] : [];
            if (!newTracked.includes(keyword)) {
                setPageLoading(true);
    
                try {
                    const userRef = doc(db, "users", userInfo.email);
                    const eventRef = collection(db, "trackedEvents");
                    newTracked.push(keyword);
    
                    const billList = [];
                    const { hits } = await searchAlgolia(keyword.includes(' ') ? `"${keyword}"` : `${keyword}`);
                    hits.forEach(hit => {
                        billList.push({ keyword: keyword, bill: buildLegisTitle(hit) });
                    });
    
                    const chunkedBillList = chunkArray(billList, 10); // Split into chunks of 10
                    const trackedSet = new Set(trackedEvents);
    
                    for (const chunk of chunkedBillList) {
                        const billNames = chunk.map(item => item.bill);
                        const q = query(eventRef, where('billsResolutions', 'array-contains-any', billNames));
    
                        const querySnapshot = await getDocs(q);
                        querySnapshot.forEach(doc => {
                            trackedSet.add(doc.data().id);
                        });
                    }
    
                    const allTrackedArr = Array.from(trackedSet);
                    const languageBills = Array.from(new Set([...billList, ...trackedLanguageBills]));
                    
                    await updateDoc(userRef, {
                        trackedWords: newTracked,
                        allTrackedEvents: allTrackedArr,
                        trackedLanguageBills: languageBills,
                    });
    
                    setDuplicateWarning(false);
                    setKeyword('');
                } catch (error) {
                    console.error('Error saving keyword:', error);
                } finally {
                    setPageLoading(false);
                }
            } else {
                setDuplicateWarning(true);
            }
        }
    };    

    const handleDeleteWord = async (word) => {
        // Remove the word from tracked data
        const newTrackedWords = trackedData.filter(e => e !== word);
        // Optimistically update the UI
        setTrackedData(newTrackedWords);

        try {
            const userRef = doc(db, "users", userInfo.email);
            const eventRef = collection(db, "trackedEvents");
            const remainingLanguageBills = trackedLanguageBills.filter(obj => obj.keyword !== word);
            let updatedTrackedEvents = new Set(trackedEvents);
            const removedBills = trackedLanguageBills
                .filter(obj => obj.keyword === word)
                .map(obj => obj.bill);

            const billChunks = chunkArray(removedBills, 10);
            for (const chunk of billChunks) {
                const q = query(eventRef, where('billsResolutions', 'array-contains-any', chunk));
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    const eventData = doc.data();
                    const { committees, billsResolutions } = eventData;

                    // Check if the event is still connected to other tracked committees
                    const hasTrackedCommittees = committees?.some(c => trackedCommitteeNames.includes(c));

                    // Check if the event is still connected to other tracked bills
                    const hasOtherTrackedBills = billsResolutions?.some(bill =>
                        trackedLegislationTitles.includes(bill) ||
                        remainingLanguageBills.some(obj => obj.bill === bill)
                    );

                    // Remove event if it has no connections
                    if (!hasTrackedCommittees && !hasOtherTrackedBills) {
                        updatedTrackedEvents.delete(eventData.id);
                    }
                });
            }
            const allTrackedArr = Array.from(updatedTrackedEvents);
            await updateDoc(userRef, {
                trackedWords: newTrackedWords,
                trackedLanguageBills: remainingLanguageBills,
                allTrackedEvents: allTrackedArr,
            });
        } catch (error) {
            // Revert state in case of an error
            setTrackedData([...newTrackedWords, word]);
        }
    };

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <TextInput
                    style={styles.textInput}
                    onChangeText={setKeyword}
                    placeholder="Enter Keyword"
                    placeholderTextColor='#838383'
                    value={keyword}
                    textAlign={'center'}
                />
                {duplicateWarning ? <Text style={styles.warning}>That keyword is already being tracked!</Text> : null}
                <View style={{ width: '100%', justifyContent: 'center' }}>
                    <Pressable
                        onPress={() => handleSaveKeyword()}
                        style={{ width: '100%' }}
                    >
                        <View style={{ flexDirection: 'row', marginBottom: 10, alignSelf: 'center' }}>
                            <AntDesign name="pluscircle" size={17} color={constants.ICON_COLOR} style={{ marginRight: 5 }} />
                            <Text style={[styles.textStyle, { alignSelf: 'center', color: constants.ICON_COLOR }]}>Add Keyword</Text>
                        </View>
                    </Pressable>
                </View>
                {pageLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                    :
                    trackedData
                        ? <View style={{ flex: 1 }}>
                            <FlatList
                                style={styles.scrollView}
                                data={trackedData}
                                renderItem={({ item }) => (
                                    <View style={[styles.button, styles.buttonClose]}>
                                        <View style={styles.rowContainer}>
                                            <View style={{ flex: 5, justifyContent: 'center' }}>
                                                <Text style={[styles.textStyle, { flex: 1, flexWrap: 'wrap' }]}>{item}</Text>
                                            </View>
                                            <View style={{ flex: .5, justifyContent: 'center' }}>
                                                <Pressable
                                                    onPress={() => handleDeleteWord(item)}
                                                >
                                                    <MaterialIcons name="cancel" size={24} color={constants.ICON_COLOR} />
                                                </Pressable>
                                            </View>
                                        </View>
                                    </View>
                                )}
                                keyExtractor={item => item}
                            />
                        </View>
                        : null
                }
            </View>
        </View>
    )
}

export default KeywordTracking

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        height: '100%',
        flex: 1
    },
    rowContainer: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
    },
    textInput: {
        color: constants.SUBTEXT_COLOR,
        fontFamily: 'Verdana',
        fontSize: 16,
        fontWeight: 'bold',
        alignSelf: 'center',
        textAlign: 'center',
        backgroundColor: constants.SECONDARY_DARK,
        height: 45,
        width: '70%',
        borderWidth: 1,
        borderRadius: 9,
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 10,
        marginBottom: 10,
    },
    scrollView: {
        marginBottom: 20,
    },
    button: {
        borderRadius: 5,
        borderWidth: 2,
        borderColor: '#131313',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        marginBottom: 1,
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginLeft: 7,
        marginBottom: 2,
        fontSize: 13.5,
        fontWeight: "bold",
        fontFamily: 'Verdana',
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        width: "95%",
        height: 20,
        marginLeft: 7,
        fontSize: 12,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
})

