import { db } from '../../db/firestore';
import { getDocs, collection, query, where, orderBy, startAfter, limit } from "firebase/firestore";
import {searchAlgolia} from '../../db/algolia';

const buildLegisTitle = (bill) => {
    const chamberType = {
        1: "H",
        2: "S",
    };
    const documentType = {
        1: "B",
        2: "R",
    };
    return `${chamberType[bill.chamber]}${documentType[bill.documentType]}${bill.number}`;
}

const getNewPrefiles = async () => {
    try {
        const prefileRef = collection(db, 'prefiles');
        const querySnapshot = await getDocs(prefileRef);

        if (querySnapshot.empty) {
            return [];
        }

        const prefileData = querySnapshot.docs.map((doc) => {
            const prefile = doc.data();
            return {
                id: prefile.legislationId,
                title: prefile.title,
                statusDate: prefile.statusDate,
                status: prefile.status,
                number: prefile.number,
                chamber: prefile.chamber,
                documentType: prefile.documentType,
            };
        });

        return prefileData;
    } catch (error) {
        console.error('Error fetching prefiles:', error);
        return [];
    }
};

const sortNewPrefiles = (prefiles) => {
    const housePrefiles = [];
    const senatePrefiles = [];
    prefiles.forEach(prefile => {
        if (prefile.chamber === '1') {
            housePrefiles.push(prefile)
        } else {
            senatePrefiles.push(prefile)
        }
    });
    return {
        house: housePrefiles,
        senate: senatePrefiles,
    }
}

const getHouseBills = async () => {
    let lastVisible;
    const docRef = collection(db, 'legislation');
    const q = await query(docRef,
        where('status', '==', 'House Prefiled'),
        orderBy("number"),
        limit(20)
    )
    const results = await getDocs(q)
        .then(result => result.docs)
        .then(docs => {
            lastVisible = docs[docs.length - 1]
            return docs.map(doc => doc.data())
        })
        .then(docs => docs.map(doc => ({
            id: doc.legislationId,
            title: doc.title,
            statusDate: doc.statusDate,
            status: doc.status,
            number: doc.number,
            chamber: doc.chamber,
            documentType: doc.documentType,
        })))
    return { results, lastVisible }
}

const getMoreHouseBills = async (after) => {
    let lastVisible;
    const docRef = collection(db, 'legislation');
    const q = await query(docRef,
        where('status', '==', 'House Prefiled'),
        orderBy("number"),
        startAfter(after),
        limit(20)
    )
    const results = await getDocs(q)
        .then(result => result.docs)
        .then(docs => {
            lastVisible = docs[docs.length - 1]
            return docs.map(doc => doc.data())
        })
        .then(docs => docs.map(doc => ({
            id: doc.legislationId,
            title: doc.title,
            statusDate: doc.statusDate,
            status: doc.status,
            number: doc.number,
            chamber: doc.chamber,
            documentType: doc.documentType,
        })))
    return { results, lastVisible }
}

const getSenateBills = async () => {
    let lastVisible;
    const docRef = collection(db, 'legislation');
    const q = await query(docRef,
        where('status', '==', 'Senate Prefiled'),
        orderBy("number"),
        limit(20)
    )
    const results = await getDocs(q)
        .then(result => result.docs)
        .then(docs => {
            lastVisible = docs[docs.length - 1]
            return docs.map(doc => doc.data())
        })
        .then(docs => docs.map(doc => ({
            id: doc.legislationId,
            title: doc.title,
            statusDate: doc.statusDate,
            status: doc.status,
            number: doc.number,
            chamber: doc.chamber,
            documentType: doc.documentType,
        })))

    return { results, lastVisible }
}

const getMoreSenateBills = async (after) => {
    let lastVisible;
    const docRef = collection(db, 'legislation');
    const q = await query(docRef,
        where('status', '==', 'Senate Prefiled'),
        orderBy("number"),
        startAfter(after),
        limit(20)
    )
    const results = await getDocs(q)
        .then(result => result.docs)
        .then(docs => {
            lastVisible = docs[docs.length - 1]
            return docs.map(doc => doc.data())
        })
        .then(docs => docs.map(doc => ({
            id: doc.legislationId,
            title: doc.title,
            statusDate: doc.statusDate,
            status: doc.status,
            number: doc.number,
            chamber: doc.chamber,
            documentType: doc.documentType,
        })))

    return { results, lastVisible }
}

const legisTitleSearch = async (text) => {
    const chamberNumType = {
        H: 1,
        S: 2,
    };
    const documentNumType = {
        B: 1,
        R: 2,
    };
    const formattedText = text.toUpperCase();
    const docRef = collection(db, 'legislation');
    const q = await query(docRef,
        where('chamber', '==', chamberNumType[formattedText[0]]),
        where('documentType', '==', documentNumType[formattedText[1]]),
        where('number', '==', formattedText.substring(2)),
        where('status', 'in', ['House Prefiled', 'Senate Prefiled']),
        orderBy("number")
    )
    return await getDocs(q)
        .then((querySnapshot) => {
            const trackedBillArr = [];
            querySnapshot.forEach((doc) => {
                const item = doc.data()
                trackedBillArr.push({
                    chamber: item.chamber,
                    documentType: item.documentType,
                    number: item.number,
                    title: item.title,
                    id: item.legislationId,
                    status: item.status,
                })
            })
            return trackedBillArr.slice()
        })
}

const handleAlgoliaSearch = async (text, pageNum) => {
    const billArr = [];
    const { hits, nbPages } = await searchAlgolia(text, pageNum, [['status:Senate Prefiled', 'status:House Prefiled']])
    for (let i = 0; i < hits.length; i += 1) {
        billArr.push({
            chamber: hits[i].chamber,
            documentType: hits[i].documentType,
            number: hits[i].number,
            title: hits[i].title,
            id: hits[i].legislationId,
            status: hits[i].status,
        })
    }
    return { billArr, nbPages }
}

export {
    buildLegisTitle,
    getNewPrefiles,
    sortNewPrefiles,
    getHouseBills,
    getMoreHouseBills,
    getSenateBills,
    getMoreSenateBills,
    legisTitleSearch,
    handleAlgoliaSearch,
}
