import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, Text, View, ScrollView, Platform } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons';

const Profile = ({ route, userInfo }) => {
    const [name, setName] = useState('')
    const headerHeight = useHeaderHeight();

    useEffect(() => {
        setName(`${userInfo.firstName} ${userInfo.lastName}`)
    }, []);

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <Text style={[styles.textStyle, { alignSelf: 'center', fontSize: 20, marginTop: 20, marginBottom: 20 }]}>{name}</Text>
                <ScrollView style={styles.scrollView}>
                    <View style={[styles.button, styles.buttonClose]}>
                        <View style={styles.rowContainer}>
                            <MaterialCommunityIcons style={{ alignSelf: 'center', marginRight: 8 }} name="email-outline" size={24} color={constants.ICON_COLOR} />
                            <View>
                                <Text style={styles.textStyle}>{userInfo.email}</Text>
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

export default Profile

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
    },
    button: {
        borderRadius: 9,
        borderWidth: 2,
        borderColor: 'black',
        paddingTop: 12,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    rowContainer: {
        flexDirection: 'row',
        flexGrow: 1,
        width: "90%",
    },
    textStyle: {
        color: constants.SUBTEXT_COLOR,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
})
