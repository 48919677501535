import React, { useState } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, View, Pressable, Text, Platform } from 'react-native'
import OOGOrdersTable from './OOGOrdersTable'
import OOGNewsTable from './OOGNewsTable'

const OOGContainer = ({ route, navigation }) => {
    const [layout, setLayout] = useState(<OOGOrdersTable navigation={navigation} />);
    const [ordersView, setOrdersView] = useState(true)
    const headerHeight = useHeaderHeight();

    const handleOrders = () => {
        setLayout(<OOGOrdersTable navigation={navigation} />);
        setOrdersView(true)
    }

    const handleNews = () => {
        setLayout(<OOGNewsTable navigation={navigation} />)
        setOrdersView(false)
    }

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <View style={styles.buttonRow}>
                    <Pressable
                        style={{ ...styles.ordersButton, backgroundColor: ordersView ? '#3a3a3a' : '#242424' }}
                        onPress={handleOrders}
                    >
                        <Text style={styles.ordersButtonText}>Executive Orders</Text>
                    </Pressable>
                    <Pressable
                        style={{ ...styles.newsButton, backgroundColor: ordersView ? '#242424' : '#3a3a3a' }}
                        onPress={handleNews}
                    >
                        <Text style={styles.newsButtonText}>News</Text>
                    </Pressable>
                </View>
                {layout}
            </View>
        </View>
    )
}

export default OOGContainer

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        flex: 1
    },
    ordersButton: {
        marginTop: 15,
        padding: 7,
        borderTopLeftRadius: 9,
        borderBottomLeftRadius: 9,
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        alignSelf: 'center'
    },
    ordersButtonText: {
        marginRight: 10,
        marginLeft: 10,
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        alignSelf: 'center',
        color: 'white',
    },
    newsButton: {
        marginTop: 15,
        padding: 7,
        borderTopRightRadius: 9,
        borderBottomRightRadius: 9,
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        alignSelf: 'center'
    },
    newsButtonText: {
        marginRight: 10,
        marginLeft: 10,
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        alignSelf: 'center',
        color: 'white',
    },
    buttonRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 10
    },
})


