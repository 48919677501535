import React, { useState, useEffect } from 'react'
import { useHeaderHeight } from '@react-navigation/elements';
import constants from '../../utils/constants';
import { StyleSheet, ActivityIndicator, Text, View, ScrollView, Button, Pressable, Platform } from 'react-native'
import { Dropdown } from 'react-native-element-dropdown';
import { SearchBar } from 'react-native-elements';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import {
    getEvents,
    getTrackedEvents,
    fillTable,
    getPastEvents,
    getCurrentEvents,
    sortEventsByChamber,
} from './meetingsHelper'

const chamberOptions = [
    { label: 'House', value: '1' },
    { label: 'Senate', value: '2' },
    { label: 'House/Senate', value: '3' },
];

const dateOptions = [
    { label: 'Current Events', value: '1' },
    { label: 'Past Events', value: '2' },
];

const MeetingsTable = ({ route, navigation, userInfo }) => {
    const { title, email } = route.params;
    const [currentTrackedEvents, setCurrentTrackedEvents] = useState();
    const [tableData, setTableData] = useState();
    const [allData, setAllData] = useState();
    const [houseData, setHouseData] = useState();
    const [senateData, setSenateData] = useState();
    const [chamberValue, setChamberValue] = useState('3');
    const [dateValue, setDateValue] = useState('1');
    const [isChamberFocus, setIsChamberFocus] = useState(false);
    const [isDateFocus, setIsDateFocus] = useState(false);
    const [page, setPage] = useState('trackedEvents');
    const [myMeetings, setMyMeetings] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const headerHeight = useHeaderHeight();

    const handleTracking = async () => {
        setMyMeetings(true)
        setPageLoading(true)
        const filteredEvents = await getTrackedEvents(userInfo);
        const chamberSortedEvents = sortEventsByChamber(filteredEvents);
        setPage('trackedEvents');
        setHouseData(chamberSortedEvents.houseArr);
        setSenateData(chamberSortedEvents.senateArr);
        setAllData(filteredEvents);
        setPageLoading(false)
    }

    const handleAll = async () => {
        setMyMeetings(false)
        setPageLoading(true)
        const allEvents = await getEvents('events');
        const chamberSortedEvents = sortEventsByChamber(allEvents)
        setPage('events');
        setHouseData(chamberSortedEvents.houseArr);
        setSenateData(chamberSortedEvents.senateArr);
        setAllData(allEvents);
        setPageLoading(false)
    }

    useEffect(() => {
        if (userInfo.allTrackedEvents.length !== currentTrackedEvents?.length) {
            if (page === 'trackedEvents') {
                handleTracking()
            } else {
                handleAll()
            }
            setCurrentTrackedEvents(userInfo.allTrackedEvents)
        }
    }, [userInfo]);

    useEffect(() => {
        if (allData) {
            let filteredData = allData;
    
            if (searchQuery) {
                filteredData = allData.filter(item => {
                    const hasMatchingCommittee = item.committees && item.committees.some(committee =>
                        committee.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                    const hasMatchingSubject = item.subject && item.subject.toLowerCase().includes(searchQuery.toLowerCase());
                    return hasMatchingCommittee || hasMatchingSubject;
                });
            }
    
            if (chamberValue === '1') {
                let arr;
                if (dateValue === '1') {
                    arr = fillTable(getCurrentEvents(houseData.filter(item => filteredData.includes(item))), navigation, email, page)
                } else {
                    arr = fillTable(getPastEvents(houseData.filter(item => filteredData.includes(item))), navigation, email, page)
                }
                setTableData(arr)
            } else if (chamberValue === '2') {
                let arr;
                if (dateValue === '1') {
                    arr = fillTable(getCurrentEvents(senateData.filter(item => filteredData.includes(item))), navigation, email, page)
                } else {
                    arr = fillTable(getPastEvents(senateData.filter(item => filteredData.includes(item))), navigation, email, page)
                }
                setTableData(arr)
            } else if (chamberValue === '3') {
                let arr;
                if (dateValue === '1') {
                    arr = fillTable(getCurrentEvents(filteredData), navigation, email, page)
                } else {
                    arr = fillTable(getPastEvents(filteredData), navigation, email, page)
                }
                setTableData(arr)
            }
        } else {
            let arr;
            arr = fillTable([], navigation, email, page)
            setTableData(arr)
        }
    }, [chamberValue, dateValue, allData, searchQuery]);
    
    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <View style={styles.trackedButtons}>
                    <Pressable style={{ ...styles.myMeetingsButton, backgroundColor: myMeetings ? '#3a3a3a' : '#242424' }} onPress={handleTracking}>
                        <Text style={styles.myMeetingsButtonText}>My Meetings</Text>
                    </Pressable>
                    <Pressable style={{ ...styles.webcalButton, backgroundColor: myMeetings ? '#242424' : '#3a3a3a' }} onPress={handleAll}>
                        <Text style={styles.webcalButtonText}>Ga Assembly Calendar</Text>
                    </Pressable>
                </View>
                <View style={styles.trackedButtons}>
                    <Dropdown
                        style={[styles.dropdown, isDateFocus && { borderColor: 'blue' }, { marginRight: 8 }]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        fontFamily='Verdana'
                        containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                        activeColor='#3f3f3f'
                        inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                        iconStyle={styles.iconStyle}
                        data={dateOptions}
                        renderLeftIcon={() => (
                            <AntDesign style={styles.icon} color={constants.ICON_COLOR} name="calendar" size={20} />
                        )}
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isDateFocus ? 'Current Events' : '...'}
                        searchPlaceholder="Search..."
                        value={dateValue}
                        onFocus={() => setIsDateFocus(true)}
                        onBlur={() => setIsDateFocus(false)}
                        onChange={item => {
                            setDateValue(item.value);
                            setIsDateFocus(false);
                        }}
                    />
                    <Dropdown
                        style={[styles.dropdown, isChamberFocus && { borderColor: 'blue' }]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        fontFamily='Verdana'
                        containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                        activeColor='#3f3f3f'
                        inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                        iconStyle={styles.iconStyle}
                        data={chamberOptions}
                        renderLeftIcon={() => (
                            <FontAwesome style={styles.icon} name="building-o" size={18} color={constants.ICON_COLOR} />
                        )}
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isChamberFocus ? 'All' : '...'}
                        searchPlaceholder="Search..."
                        value={chamberValue}
                        onFocus={() => setIsChamberFocus(true)}
                        onBlur={() => setIsChamberFocus(false)}
                        onChange={item => {
                            setChamberValue(item.value);
                            setIsChamberFocus(false);
                        }}
                    />
                </View>
                <SearchBar
                    placeholder="Search Meetings"
                    onChangeText={(text) => setSearchQuery(text)}
                    value={searchQuery}
                    clearIcon
                    darkTheme
                    containerStyle={{ backgroundColor: constants.PRIMARY_DARK, width: '98%', alignSelf: 'center', padding: 0, marginBottom: 15 }}
                    inputContainerStyle={{ borderRadius: 9 }}
                    inputStyle={{ color: 'white' }}
                />
                <ScrollView style={styles.scrollView}>
                    {pageLoading ?
                        <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                        :
                        tableData
                    }
                </ScrollView>
            </View>
        </View>
    )
}

export default MeetingsTable

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        flex: 1
    },
    myMeetingsButton: {
        padding: 7,
        borderTopLeftRadius: 9,
        borderBottomLeftRadius: 9,
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        alignSelf: 'center',
        ...Platform.select({
            ios: {
                marginTop: 15,
            },
            android: {
                marginTop: 30,
            }
        })
    },
    webcalButton: {
        paddingBottom: 7,
        paddingTop: 7,
        borderTopRightRadius: 9,
        borderBottomRightRadius: 9,
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        alignSelf: 'center',
        ...Platform.select({
            ios: {
                marginTop: 15,
            },
            android: {
                marginTop: 30,
            }
        })
    },
    myMeetingsButtonText: {
        marginRight: 10,
        marginLeft: 10,
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        alignSelf: 'center',
        color: 'white',
    },
    webcalButtonText: {
        marginRight: 10,
        marginLeft: 10,
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        alignSelf: 'center',
        color: 'white',
    },
    dropdown: {
        backgroundColor: '#242424',
        height: 40,
        width: 170,
        borderColor: 'gray',
        borderWidth: 1.5,
        borderRadius: 9,
        paddingHorizontal: 8,
        marginBottom: 10,
    },
    searchBar: {
        height: 60,
        borderColor: '#3a3a3a',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 20,
        alignSelf: 'center',
        width: "98%",
        color: 'white',
        backgroundColor: '#242424',
        marginVertical: 10,
    },
    scrollView: {
        // marginBottom: 100
    },
    button: {
        borderRadius: 5,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    highlightButton: {
        borderRadius: 20,
        padding: 5,
        width: 120,
        height: 30,
        marginBottom: 8,
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 10
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    icon: {
        marginRight: 5,
    },
    label: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
        color: '#e5e5e5'
    },
    selectedTextStyle: {
        fontSize: 16,
        color: '#e5e5e5'
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
})
