import React from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, Text, View, Pressable, ScrollView, Platform } from 'react-native'
import { AntDesign, FontAwesome } from '@expo/vector-icons';

const OOGNewsInfo = ({ route, navigation }) => {
    const { info, email } = route.params
    const headerHeight = useHeaderHeight();

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={{ marginTop: headerHeight, flex: 1 }}>
                <Text style={styles.subjectStyle}>{info?.subject}</Text>
                <View style={{ flexDirection: 'row', margin: 15 }}>
                    <AntDesign style={{ alignSelf: 'center', marginRight: 8 }} name="calendar" size={16} color={constants.SUBTEXT_COLOR} />
                    <Text style={styles.dateStyle}>{info?.start}</Text>
                </View>
                <ScrollView style={styles.scrollView}>
                    <View style={[styles.button, styles.buttonClose]}>
                        <Text style={styles.subTextStyle}>{info?.emailBody}</Text>
                    </View>
                </ScrollView>
            </View>

        </View>
    )
}

export default OOGNewsInfo

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    subjectStyle: {
        color: constants.TITLE_COLOR,
        width: "95%",
        marginLeft: 15,
        fontSize: 17,
        fontFamily: 'Verdana',
        fontWeight: "bold",
    },
    dateStyle: {
        color: constants.TITLE_COLOR,
        width: "95%",
        fontSize: 14,
        fontWeight: "bold",
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        width: "95%",
        marginLeft: 15,
        fontSize: 14,
        fontFamily: 'Verdana'
    },
    textStyle: {
        color: constants.TITLE_COLOR,
    },
    button: {
        borderRadius: 9,
        borderWidth: 2,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    billButton: {
        borderRadius: 20,
        elevation: 2,
        width: "90%",
        height: 50,
        marginBottom: 10,
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
})