import React, { useState, useEffect } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, FlatList, Pressable, Platform } from 'react-native'
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { SearchBar } from 'react-native-elements';
import { db } from '../../db/firestore';
import { doc, getDoc, getDocs, collection, updateDoc, query, where } from "firebase/firestore";

const CommitteeTracking = ({ route, userInfo }) => {
    const [currentCommitteeNames, setCurrentCommitteeNames] = useState([]);
    const [trackedCommittees, setTrackedCommittees] = useState([]);
    const [trackedCommitteeNames, setTrackedCommitteeNames] = useState([]);
    const [trackedLegislationTitles, setTrackedLegislationTitles] = useState([]);
    const [trackedLanguageBills, setTrackedLanguageBills] = useState([]);
    const [trackedEvents, setTrackedEvents] = useState([]);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [committees, setCommittees] = useState([])
    const [pageLoading, setPageLoading] = useState(true);
    const headerHeight = useHeaderHeight();

    const chamberObj = {
        1: "House",
        2: "Senate",
    }

    useEffect(() => {
        async function getCommittees() {
            const docRef = doc(db, 'committees', '1031');
            await getDoc(docRef)
                .then(doc => doc.data().committees)
                .then(docs => docs.map(doc => ({
                    chamber: doc.chamber,
                    id: doc.id,
                    name: doc.name
                })))
                .then(committeeData => {
                    setCommittees(committeeData)
                })
            setPageLoading(false);
        }
        getCommittees()
    }, []);

    useEffect(() => {
        if (userInfo.trackedCommitteeNames.length !== currentCommitteeNames) {
            setTrackedCommittees(userInfo.trackedCommittees ? userInfo.trackedCommittees : []);
            setTrackedCommitteeNames(userInfo.trackedCommitteeNames ? userInfo.trackedCommitteeNames : []);
            setTrackedEvents(userInfo.allTrackedEvents ? userInfo.allTrackedEvents : []);
            setTrackedLegislationTitles(userInfo.trackedLegislationTitles ? userInfo.trackedLegislationTitles : []);
            setTrackedLanguageBills(userInfo.trackedLanguageBills ? userInfo.trackedLanguageBills : []);
            setCurrentCommitteeNames(userInfo.trackedCommitteeNames)
        }
    }, [userInfo]);

    const handleSearch = (text) => {
        const formattedText = text.toLowerCase()
        setSearch(text)
        if (formattedText.length > 1) {
            var searchArr = committees.filter(a => a.name.toLowerCase().includes(formattedText));
            setData(searchArr)
        } else {
            setData([])
        }
    }

    const handleTrack = async (item) => {
        // Optimistically update UI
        const fullCommitteeName = `${item.name} (${chamberObj[item.chamber]})`
        setTrackedCommittees([...trackedCommittees, item]);
        setTrackedCommitteeNames([...trackedCommitteeNames, fullCommitteeName]);
        const userRef = doc(db, "users", userInfo.email);
        const eventRef = collection(db, "trackedEvents");

        const q = query(eventRef,
            where('committees', 'array-contains', fullCommitteeName),
        )
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            trackedEvents.push(doc.data().id);
        });

        const allTrackedArr = [...new Set(trackedEvents)];

        try {
            await updateDoc(userRef, {
                trackedCommittees: [...trackedCommittees, item],
                trackedCommitteeNames: [...trackedCommitteeNames, fullCommitteeName],
                allTrackedEvents: allTrackedArr
            });
        } catch (error) {
            // Revert state if update fails
            setTrackedCommittees(trackedCommittees.filter(e => e.id !== item.id));
            setTrackedCommitteeNames(trackedCommitteeNames.filter(e => e !== fullCommitteeName));
        }
    }

    const handleDeleteCommittee = async (item) => {
        const fullCommitteeName = `${item.name} (${chamberObj[item.chamber]})`;

        // Optimistically update UI
        const deleteCommitteesArr = trackedCommittees.filter(e => e.id !== item.id);
        const deleteCommitteeNamesArr = trackedCommitteeNames.filter(e => e !== fullCommitteeName);
        setTrackedCommittees(deleteCommitteesArr);
        setTrackedCommitteeNames(deleteCommitteeNamesArr);

        try {
            const userRef = doc(db, "users", userInfo.email);
            const eventRef = collection(db, "trackedEvents");

            // Query events associated with this committee
            const q = query(eventRef, where('committees', 'array-contains', fullCommitteeName));
            const querySnapshot = await getDocs(q);

            // Prepare updated allTrackedEvents
            let updatedAllTrackedEvents = [...new Set(trackedEvents)];

            querySnapshot.forEach((doc) => {
                const eventData = doc.data();
                const { committees, billsResolutions } = eventData;

                // Check if event is associated with any other tracked committees
                const hasOtherTrackedCommittees = committees?.some(c => deleteCommitteeNamesArr.includes(c));

                // Check if event is associated with any tracked bills/resolutions
                const hasTrackedBills = billsResolutions?.some(bill =>
                    trackedLegislationTitles.includes(bill) ||
                    trackedLanguageBills.some(obj => obj.bill === bill)
                );

                // If no other associations exist, remove the event from tracked events
                if (!hasOtherTrackedCommittees && !hasTrackedBills) {
                    updatedAllTrackedEvents = updatedAllTrackedEvents.filter(eventId => eventId !== eventData.id);
                }
            });

            // Update Firestore
            await updateDoc(userRef, {
                trackedCommittees: deleteCommitteesArr,
                trackedCommitteeNames: deleteCommitteeNamesArr,
                allTrackedEvents: updatedAllTrackedEvents,
            });
        } catch (error) {
            console.log(error)
            // Revert state if update fails
            setTrackedCommittees([...deleteCommitteesArr, item]);
            setTrackedCommitteeNames([...deleteCommitteeNamesArr, fullCommitteeName]);
        }
    };

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <SearchBar
                    placeholder="Search Committees to track"
                    onChangeText={(text) => handleSearch(text)}
                    value={search}
                    clearIcon
                    darkTheme
                    containerStyle={{ backgroundColor: constants.PRIMARY_DARK, width: '98%', alignSelf: 'center', padding: 0, marginBottom: 15 }}
                    inputContainerStyle={{ borderRadius: 9 }}
                />
                {(data.length > 0)
                    ? <FlatList
                        style={styles.scrollView}
                        data={data}
                        renderItem={({ item }) => (
                            <View style={[styles.button, styles.buttonClose]} >
                                <View style={styles.rowContainer}>
                                    <View style={{ flex: 5, justifyContent: 'center' }}>
                                        <Text style={styles.textStyle}>{item.name}</Text>
                                        <Text style={styles.textStyle}>({chamberObj[item.chamber]})</Text>
                                    </View>
                                    <View style={{ flex: .5, justifyContent: 'center' }}>
                                        {(trackedCommittees.some(committee => committee.id === item.id)) ?
                                            <Pressable
                                                onPress={() => handleDeleteCommittee(item)}
                                            >
                                                <MaterialIcons name="cancel" size={24} color="gray" />
                                            </Pressable>
                                            :
                                            <Pressable
                                                onPress={() => handleTrack(item)}
                                            >
                                                <AntDesign name="pluscircle" size={24} color={constants.ICON_COLOR} />
                                            </Pressable>
                                        }
                                    </View>
                                </View>
                            </View>
                        )}
                    />
                    : null
                }
                {pageLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                    :
                    (trackedCommittees?.length > 0 && data.length === 0) ?
                        <View style={{ flex: 1 }}>
                            <View style={{ margin: 15 }}>
                                <Text style={styles.textStyle}>Tracked Committees</Text>
                            </View>
                            <FlatList
                                style={styles.scrollView}
                                data={trackedCommittees}
                                renderItem={({ item }) => (
                                    <View style={[styles.button, styles.buttonClose]}>
                                        <View style={styles.rowContainer}>
                                            <View style={{ flex: 5, justifyContent: 'center' }}>
                                                <View style={{}}>
                                                    <Text style={[styles.textStyle, { flex: 1, flexWrap: 'wrap' }]}>{item.name}</Text>
                                                </View>
                                                <Text style={styles.textStyle}>({chamberObj[item.chamber]})</Text>
                                            </View>
                                            <View style={{ flex: .5, justifyContent: 'center' }}>
                                                <Pressable
                                                    onPress={() => handleDeleteCommittee(item)}
                                                >
                                                    <MaterialIcons name="cancel" size={24} color={constants.ICON_COLOR} />
                                                </Pressable>
                                            </View>
                                        </View>
                                    </View>
                                )}
                            />
                        </View>
                        : null
                }
            </View>
        </View>
    )
}

export default CommitteeTracking

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        height: '100%',
        flex: 1
    },
    rowContainer: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
    },
    listItems: {
        backgroundColor: "#D3D3D3",
        borderWidth: 1,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 6,
    },
    scrollView: {
        marginBottom: 20,
    },
    button: {
        borderRadius: 5,
        borderWidth: 2,
        borderColor: '#131313',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        marginBottom: 1,
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        width: "90%",
        marginLeft: 7,
        marginBottom: 2,
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: 'Verdana',
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        width: "95%",
        height: 20,
        marginLeft: 7,
        fontSize: 12,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
})
